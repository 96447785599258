import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
  deleteObject,
} from "firebase/storage"
import { v4 as uuid } from 'uuid'

import { storage } from './config'

export const uploadFile = (image, path) => {
  const imageRef = storageRef(storage, `${path}/${uuid()}.jpeg`);
  return new Promise((resolve, reject) => {
    uploadBytes(imageRef, image)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((url) => {
            resolve(url)
          })
          .catch((error) => {
            reject(error)
          });
      })
      .catch((error) => {
        reject(error)
      });
  })
}

export const deleteFileByUrl = (url) => {
  const ref = storageRef(storage, url)
  deleteObject(ref)
}