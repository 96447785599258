import imageCompression from 'browser-image-compression'
import { deleteFileByUrl, uploadFile } from '../services/firebase/storage'

const defaultOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
}

export const compressImage = (image, options={}) => new Promise(async (resolve, reject) => {
  try {
    const compressed = await imageCompression(image, {...defaultOptions, ...options,})
    resolve(compressed)
  } catch (error) {
    reject(error)
  }
})

export const compressAndUploadImage = ({image, options={}, path, old}) => new Promise(async (resolve, reject) => {
  try {
    const compressed = await compressImage(image, options)
    const url = await uploadFile(compressed, path)
    resolve(url)
    if (old) {
      deleteFileByUrl(old)
    }
  }
  catch (error) {
    reject(error)
  }
})