import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	product: null,
	promo: null,
	products: [],
	additions: [],
	promos: [],
	productsMap: {},
	additionsMap: {},
	promosMap: {},
}

export const productSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		selectProduct: (state, action) => {
			state.product = action.payload
		},
		selectPromo: (state, action) => {
			state.promo = action.payload
		},
		setProducts: (state, action) => {
			state.products = action.payload
			state.productsMap = action.payload.reduce((acc, product) => {
				acc[product.id] = product
				return acc
			}, {})
		},
		setAdditions: (state, action) => {
			state.additions = action.payload
			state.additionsMap = action.payload.reduce((acc, addition) => {
				acc[addition.id] = addition
				return acc
			}, {})
		},
		setPromos: (state, action) => {
			state.promos = action.payload
			state.promosMap = action.payload.reduce((acc, promo) => {
				acc[promo.id] = promo
				return acc
			}, {})
		},
	},
})

export const { selectProduct, selectPromo, setProducts, setAdditions, setPromos } =
	productSlice.actions

export default productSlice.reducer
