export const MENU_ITEMS = [
	{ name: 'Inicio', icon: 'home', path: '/home' },
	{
		name: 'Mi negocio',
		icon: 'drivers-license',
		path: '/information',
	},
	{ name: 'Pedidos', icon: 'server', path: '/orders' },
	{
		name: 'Productos',
		icon: 'cubes',
		path: '/products',
	},
	{ name: 'Reporte', icon: 'pie-chart', path: '/reports' },
	{ name: 'Facturación y pagos', icon: 'file-excel', path: '/payments' },
	// { name: 'Ads', icon: 'bullhorn', path: '/ads' },
	{ name: 'Soporte', icon: 'question-circle', path: '/support' },
]

export const PARTNER_ITEMS = [
	{ name: 'Inicio', icon: 'home', path: '/home' },
	{
		name: 'Cuenta',
		icon: 'drivers-license',
		path: '/account',
	},
	{
		name: 'Negocios',
		icon: 'shopping-bag',
		path: '/companies',
	},
	{ name: 'Zonas', icon: 'map', path: '/zones' },
	{ name: 'Pedidos', icon: 'server', path: '/orders' },
	{ name: 'Reporte', icon: 'pie-chart', path: '/reports' },
	{ name: 'Facturación y pagos', icon: 'file-excel', path: '/payments' },
	// { name: 'Ads', icon: 'bullhorn', path: '/ads' },
	{ name: 'Soporte', icon: 'question-circle', path: '/support' },
]
