import * as yup from 'yup'

export const promoSchema = yup
	.object({
		name: yup
			.string()
			.required('Debes ingresar el nombre de la promoción')
			.min(3, 'El nombre debe tener al menos 3 caracteres'),
		description: yup
			.string()
			.required('Debes ingresar una descripción de tu producto'),
		tag: yup.string(),
		type: yup.string(),
		value: yup.number().required('Debes ingresar el valor de tu promoción'),
		image: yup.string(),
		status: yup.bool().required().default(true),
		applyAdditions: yup.bool().required().default(false),
		applyRequired: yup.bool().required().default(false),
	})
	.required()
