import axios from 'axios'
import { env } from '../../config/environment'

export const fetchProducts = id => {
	const url = `${env.apiUrl}/products/company?company=${id}`
	return axios.get(url)
}

export const updateProducts = body => {
	const url = `${env.apiUrl}/products?ID=${body.id}`
	return axios.put(url, body)
}

export const createProducts = body => {
	const url = `${env.apiUrl}/products`
	return axios.post(url, body)
}

export const createProductsList = body => {
	const url = `${env.apiUrl}/products/list`
	return axios.post(url, body)
}

export const fetchProductsByIds = ids => {
	const url = `${env.apiUrl}/products/ids`
	return axios.post(url, { ids })
}

export const fetchPromos = id => {
	const url = `${env.apiUrl}/promos/company?company=${id}`
	return axios.get(url)
}

export const fetchPromosByIds = ids => {
	const url = `${env.apiUrl}/promos/ids`
	return axios.post(url, { ids })
}

export const createPromo = body => {
	const url = `${env.apiUrl}/promos`
	return axios.post(url, body)
}

export const updatePromo = body => {
	const url = `${env.apiUrl}/promos?ID=${body.id}`
	return axios.put(url, body)
}
