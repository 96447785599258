import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-responsive-modal'
import { DEFAULT_VALUES } from '../../utils/default'
import { Input } from '@chakra-ui/react'

const ProductSelectionModal = ({
	multi = true,
	isOpen,
	onClose,
	product,
	index = -1,
}) => {
	const { products } = useSelector(store => store.products)
	const [selection, setSelection] = useState(product?.options ?? [])
	const [query, setQuery] = useState('')
	const [name, setName] = useState(product?.name ?? '')

	const handleSelect = product => {
		if (selection.includes(product.id)) {
			setSelection(selection.filter(p => p !== product.id))
			return
		}
		if (multi) {
			setSelection([...selection, product.id])
		} else {
			setSelection([product.id])
		}
	}

	return (
		<Modal open={isOpen} onClose={() => onClose()} center>
			<div className='modal-content w-[300px] md:w-[420px]'>
				<div className='modal-header mb-2'>
					<h2>Nombre</h2>
					<div className='search-bar'>
						<Input
							type='text'
							placeholder='Nombre'
							value={name}
							onChange={e => setName(e.target.value)}
							className='mb-5'
						/>
					</div>
					<h2>
						{selection.length === 0
							? 'Selecciona tus productos'
							: `${selection.length} productos seleccionados`}
					</h2>
					<div className='search-bar'>
						<Input
							type='text'
							placeholder='Buscar producto'
							value={query}
							onChange={e => setQuery(e.target.value)}
						/>
					</div>
				</div>
				<div className='modal-body flex flex-col gap-4 max-h-[500px] overflow-y-scroll py-4'>
					{products
						.filter(p =>
							p.name.toLowerCase().includes(query.trim().toLowerCase())
						)
						.map(product => (
							<div
								key={product.id}
								className={`flex flex-row items-center gap-2 product cursor-pointer`}
								onClick={() => handleSelect(product)}
							>
								<div className='product-image w-[32px] h-[32px]'>
									<img
										src={
											product.image && product.image.length > 0
												? product.image
												: DEFAULT_VALUES['square-image']
										}
										alt={product.name}
										className='rounded-lg'
									/>
								</div>
								<div className='product-info flex flex-col'>
									<h3
										className={`max-w-[350px] overflow-ellipsis ${
											selection.includes(product.id)
												? 'font-bold'
												: 'text-[#777]'
										}`}
									>
										{product.name}
									</h3>
								</div>
							</div>
						))}
				</div>
				<div className='modal-footer flex flex-row items-center pt-3'>
					<button className='text-[#f05112] py-2 px-4 flex-1' onClick={onClose}>
						Cancelar
					</button>
					<button
						className='bg-[#f05112] text-[#fff] py-2 px-4 rounded-md flex-1'
						onClick={() =>
							onClose({ product: { options: selection, name }, index })
						}
					>
						Guardar
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default ProductSelectionModal
